import React from "react";

const Wallet = () => {
  return (
    <>
      <div className="tab-pane fade" id="liton_tab_1_8">
        <div className="ltn__myaccount-tab-content-inner">
        <h4>Smart Crowd Wallet</h4>
          <p>You don't have any property yet.</p>
        </div>
      </div>
    </>
  );
};
export default Wallet;
